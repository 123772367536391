import React from 'react'
import { CSSObject, SerializedStyles, css } from '@emotion/react'

interface ItemLabelProps {
  label: string
  value?: string | string[]
  itemCss?: CSSObject | SerializedStyles
  empty?: string
}

export const ItemLabel: React.FC<ItemLabelProps> = ({ label, value, itemCss, empty }) => {
  return (
    <div css={[itemCss, itemLabelStyle]}>
      <p>{label}</p>
      {Array.isArray(value) ? (
        value.length === 0 && empty ? (
          <p>{empty}</p>
        ) : (
          value.map((v, index) => (
            <p key={index} className="item-value">
              {v}
            </p>
          ))
        )
      ) : (
        <p className="item-value">{value || empty || '-'}</p>
      )}
    </div>
  )
}

const itemLabelStyle = css({
  p: {
    '&:first-of-type': {
      fontSize: '12px',
    },
  },
  '.item-value': {
    fontWeight: 'bold',
    marginTop: '0.5rem',
    fontSize: '14px',
  },
})
