import { useAppDispatch } from "@/app/hooks"
import { deleteCheckInPaymentData } from "@/features/checkIn/check-in-payment-slice"
import { deleteCheckInData } from "@/features/checkIn/check-in-slice"
import { deleteCustomCheckInData } from "@/features/checkIn/custom-check-in-field-slice"
import { deleteSearchReservations } from "@/features/checkIn/search-reservations-slice"
import { clearTimeSelection } from "@/features/checkIn/time-selection-slice"

export const useDeleteCheckIn = () => {
  const dispatch = useAppDispatch()

  const deleteCheckIn = () => {
    dispatch(deleteCheckInData())
    dispatch(deleteSearchReservations())
    dispatch(deleteCustomCheckInData())
    dispatch(deleteCheckInPaymentData())
    dispatch(clearTimeSelection())
  }

  return { deleteCheckIn }
}