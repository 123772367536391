import React, { useEffect, useState } from 'react'
import { FormLayout } from '@/components/template/form-layout'
import { Layout } from '@/components/template/layout'
import { useTranslation } from 'react-i18next'
import { CustomCheckInForm } from '@/components/organisms/custom-check-in-form'
import { useAppSelector } from '@/app/hooks'
import { Button } from '@/components/atoms/button'
import { pcScreenStyle, spacerTopStyle } from '@/styles/common'
import { useNavigate } from 'react-router'
import { useForm } from 'react-hook-form'
import { formButtonStyle } from '@/styles/form'
import { AppDispatch } from '@/app/store'
import { useDispatch } from 'react-redux'
import { fetchCustomFields, setCustomCheckInData } from '@/features/checkIn/custom-check-in-field-slice'
import { getCheckInPayments } from '@/features/checkIn/check-in-payment-slice'
import { useQuerySearchParams } from '@/hooks/use-query-search-params'
import { errorHandler } from '@/libs/errors'
import { isEmpty } from 'lodash'
import { getValueByLanguage } from '@/libs/get-value-by-language'
import { LanguageType } from '@/i18n'
import { CheckInStep } from '@/components/organisms/checkIn/check-in-step'
import { fetchTimeSelections, setSelectedTimes } from '@/features/checkIn/time-selection-slice'
import { TimeSelectionInForm } from '@/components/organisms/timeSelection/time-selection-in-form'
import { css } from '@emotion/react'

export const CustomCheckIn: React.FC = () => {
  let ignoreUseEffectWithStrict = false
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const searchParams = useQuerySearchParams<{ hotelId: string }>()
  const customCheckInFields = useAppSelector(state => state.customFields.customCheckin)
  const { timeSelections, selectedTimes, loading } = useAppSelector(state => state.timeSelection)
  const customCheckInFieldObj = customCheckInFields
    .filter(({ inputType }) => inputType !== 'none')
    .reduce((acc, currentField) => {
      const name = getValueByLanguage<string>({ object: currentField, lang: language as LanguageType, key: 'title' })
      acc[name] = currentField.value

      return acc
    }, {})

  const paymentSetting = useAppSelector(state => state.paymentSetting.settings)
  const { hasPaymentPlugins } = useAppSelector(state => state.hotelGuide)
  const checkInData = useAppSelector(state => state.checkIn.checkInData.fields)
  const [isFetchedComponentData, setIsFetchedComponentData] = useState(false)

  const useFormReturn = useForm({
    mode: 'all',
    shouldFocusError: true,
    defaultValues: { ...customCheckInFieldObj, timeSelections: selectedTimes },
  })
  const {
    handleSubmit,
    formState: { isValid },
  } = useFormReturn

  const makeNextUrl = async () => {
    const paymentMethodValues: string[] = []
    const selfCheckinPaymentSetting = paymentSetting?.payment_time?.find(item => item.value === 'CHECKIN')
    if (selfCheckinPaymentSetting && selfCheckinPaymentSetting.is_enabled) {
      for (let index = 0; index < selfCheckinPaymentSetting.payment_method.length; index++) {
        const paymentMethod = selfCheckinPaymentSetting.payment_method[index]
        if (paymentMethod.is_enabled) {
          if (paymentMethod.value === 'DIRECT') {
            paymentMethodValues.push('cash')
          } else if (paymentMethod.value === 'CREDIT_CARD') {
            paymentMethodValues.push('creditCard')
          }
        }
      }
    }
    let payments
    if (checkInData?.reservationIds) {
      payments = await dispatch(
        getCheckInPayments({
          reservationIds: checkInData.reservationIds,
          hotelId: searchParams.hotelId,
        }),
      ).unwrap()
    }
    if (hasPaymentPlugins && payments?.totalAmount > 0 && paymentMethodValues.length) {
      return '/checkin-payment'
    } else {
      return '/checkin-confirm'
    }
  }

  const onSubmit = async formValues => {
    dispatch(setCustomCheckInData(customCheckInFields))
    dispatch(setSelectedTimes(formValues.timeSelections))
    navigate(await makeNextUrl())
  }

  const fetchComponentData = async () => {
    try {
      ignoreUseEffectWithStrict = true
      const selectReservationIds = checkInData?.selectedReservations?.map(reservation => reservation.reservationId) || []
      const [_customCheckInResp, _timeSelectionsResp] = await Promise.all([
        dispatch(fetchCustomFields({ hotelId: searchParams.hotelId })).unwrap(),
        selectReservationIds.length &&
          dispatch(
            fetchTimeSelections({
              hotelId: searchParams.hotelId,
              reservationsIds: selectReservationIds,
            }),
          ).unwrap(),
      ])
      if (isEmpty(customCheckInFields) && isEmpty(_customCheckInResp?.customCheckin) && isEmpty(_timeSelectionsResp)) {
        const url = await makeNextUrl()
        navigate(url, { replace: true })
        return
      }
      setIsFetchedComponentData(true)
    } catch (error) {
      console.error(error)
      errorHandler({ error })
    }
  }

  useEffect(() => {
    if (ignoreUseEffectWithStrict) {
      return
    }
    fetchComponentData()
  }, [])

  return (
    <Layout>
      {isFetchedComponentData && (
        <>
          <CheckInStep current={2} />
          <form onSubmit={handleSubmit(onSubmit)} css={pcScreenStyle} style={{ paddingTop: '1rem' }}>
            {!isEmpty(customCheckInFields) && (
              <FormLayout>
                <CustomCheckInForm useFormReturn={useFormReturn} />
              </FormLayout>
            )}

            {!isEmpty(timeSelections) && !loading && (
              <FormLayout layoutCss={css({ marginTop: '1rem' })}>
                <TimeSelectionInForm useFormReturn={useFormReturn} />
              </FormLayout>
            )}

            <Button disabled={!isValid || loading} buttonCss={[formButtonStyle, spacerTopStyle]} text={t('Next')} type="submit" />
          </form>
        </>
      )}
    </Layout>
  )
}
