import { http } from '@/apis/aipass'
import { env } from '@/libs/env'
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Reservation } from '../checkIn/search-reservations-slice'

export interface SpaceReservationInput {
  name: string
  nameKana: string
  tel: string
  email: string
  address: string
  checkinDate: string
  checkoutDate: string
}

export interface AssignSpaceInput {
  usageFrom: string // YYYY-MM-DD HH:MM:DD
  usageTo: string // YYYY-MM-DD HH:MM:DD
  spaceId: string
  reservationUserName: string
  pax: number
  answer: string
}

export interface CreateSpaceReservationInput {
  assignSpaces: AssignSpaceInput[]
  paymentMethodType: string
  hotelId: string
  cardToken?: string
  receiptName?: string
}
export type AlignmentReservationInput = CreateSpaceReservationInput & {
  reservationId: string
}
export type NewReservationInput = CreateSpaceReservationInput & {
  newReservation: SpaceReservationInput
}

export const createSpaceReservation = createAsyncThunk(
  'space/createSpaceReservation',
  async (input: AlignmentReservationInput | NewReservationInput, { rejectWithValue }) => {
    try {
      const response = await http({
        method: 'post',
        url: `${env('BASE_API_V3')}/guest/spaces/reservations`,
        data: input,
      })
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const createSpaceReservationWithoutAuth = createAsyncThunk(
  'space/createSpaceReservationWithoutAuth',
  async (input: AlignmentReservationInput, { rejectWithValue }) => {
    try {
      const response = await http({
        method: 'post',
        url: `${env('BASE_API_V3')}/guest/spaces/reservations-without-auth`,
        data: input,
      })
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

const spaceReservationSlice = createSlice({
  name: 'spaceReservations',
  initialState: {
    reservationInfo: {} as Reservation,
    hasRoomSettingKey: false,
  },
  reducers: {
    setReservationInfo: (state, action) => {
      state.reservationInfo = action.payload
    },
    clearReservationInfo: state => {
      state.reservationInfo = {} as Reservation
    },
    setHasRoomSettingKey: (state, action: PayloadAction<boolean>) => {
      state.hasRoomSettingKey = action.payload
    },
  },
})

export const { clearReservationInfo, setReservationInfo, setHasRoomSettingKey } = spaceReservationSlice.actions
export const spaceReservationReducer = spaceReservationSlice.reducer
