import { http } from '@/apis/aipass'
import { LanguageType } from '@/i18n'
import { env } from '@/libs/env'
import { getFirstName, getSurname } from '@/utils/name'
import { inputKatakana } from '@/utils/zod-validate-map'
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { z } from 'zod'

const baseSchema = z.object({
  lastName: z.string().min(1),
  firstName: z.string().min(1),
  gender: z.string(),
  telephone: z.string(),
  address: z.string().min(1),
  company: z.string().nullish(),
  occupation: z.string().nullish(),
  otherOccupation: z.string().nullish(),
})
const birthDateSchema = z
  .object({
    birthYear: z
      .string()
      .min(1, { message: t('Please Enter {{fieldName}} field', { fieldName: t('year') }) })
      .max(4),
    birthMonth: z
      .string()
      .min(1, { message: t('Please Enter {{fieldName}} field', { fieldName: t('month') }) })
      .max(2),
    birthDay: z
      .string()
      .min(1, { message: t('Please Enter {{fieldName}} field', { fieldName: t('day') }) })
      .max(2),
  })
  .refine(
    ({ birthYear, birthMonth, birthDay }) => {
      const birthDate = `${birthYear.padStart(4, '0')}-${birthMonth.padStart(2, '0')}-${birthDay.padStart(2, '0')}`
      const formatBirthDate = dayjs(birthDate, 'YYYY-MM-DD')
      return formatBirthDate.isValid() && formatBirthDate.format('YYYY-MM-DD') === birthDate
    },
    {
      path: ['birthDay'],
      message: `${t('Invalid date')} ${t('Please enter in {{format}} format', { format: 'YYYY-MM-DD' })}`,
    },
  )

export const jpnSchema = z
  .object({
    nationality: z.literal('JPN'),
    familyNameKana: inputKatakana(),
    givenNameKana: inputKatakana(),
    postalCode: z.string().max(7).nullish(),
  })
  .and(z.intersection(baseSchema, birthDateSchema))

export const njpSchema = z
  .object({
    nationality: z.literal('NJP'),
  })
  .and(z.intersection(baseSchema, birthDateSchema))

export interface AccountInfo {
  id: string
  nationality: 'JPN' | 'NJP'
  lastName: string
  firstName: string
  surName: string
  email: string
  name?: string
  nameKana?: string
  familyNameKana?: string
  givenNameKana?: string
  picture?: string
  nameLatin?: string
  gender: 'M' | 'F'
  birthDate: string
  birthYear?: string
  birthMonth?: string
  birthDay?: string
  telephone: string
  postalCode: string | null
  prefecture: string
  address: string
  occupation: string
  otherOccupation: string
  company: string
}

export const checkUserDataValid = userData => {
  if (!userData) return false

  return !!(userData['name'] && userData['birthDate'] && userData['gender'] && userData['address'] && userData['telephone'])
}

export const putAccountInfo = createAsyncThunk('user/putAccountInfo', async (userData: AccountInfo, { rejectWithValue }) => {
  try {
    const isJp = userData.nationality === 'JPN'
    const user = {
      ...userData,
      name: isJp ? `${userData.lastName} ${userData.firstName}` : `${userData.firstName} ${userData.lastName}`,
      nameKana: isJp ? `${userData.familyNameKana} ${userData.givenNameKana}` : '',
    }

    const response = await http({
      method: 'put',
      url: `${env('BASE_API_V3')}/me/account-info`,
      data: { user },
    })

    return { ...user, id: response.data.userId }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const getAccountInfo = createAsyncThunk(
  'user/getAccountInfo',
  async (opt: { language?: LanguageType } | undefined, { rejectWithValue, dispatch }) => {
    try {
      const response = await http(
        {
          method: 'get',
          url: `${env('BASE_API_V3')}/me/account-info`,
        },
        dispatch,
      )
      return { ...opt, user: response.data?.user }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const accountInfoSlice = createSlice({
  name: 'user',
  initialState: {
    user: {} as AccountInfo,
    loading: false,
  },
  reducers: {
    setNationality: (state, action: PayloadAction<Pick<AccountInfo, 'nationality'>>) => {
      state.user.nationality = action.payload.nationality
    },
  },
  extraReducers(builder) {
    builder.addCase(putAccountInfo.fulfilled, (state, action) => {
      state.user = action.payload
    })
    builder.addCase(getAccountInfo.pending, state => {
      state.loading = true
    })
    builder.addCase(getAccountInfo.fulfilled, (state, action) => {
      const lang = action.payload.user.nationality === 'JPN' || !action.payload.user.nationality ? 'ja' : 'en'
      state.user = {
        ...action.payload.user,
        lastName: getSurname(lang, action.payload.user.name),
        firstName: getFirstName(lang, action.payload.user.name),
        givenNameKana: action.payload.user.nameKana?.split(' ')[1],
        familyNameKana: action.payload.user.nameKana?.split(' ')[0],
        birthYear: action.payload.user.birthDate ? dayjs(action.payload.user.birthDate).format('YYYY') : undefined,
        birthMonth: action.payload.user.birthDate ? dayjs(action.payload.user.birthDate).format('MM') : undefined,
        birthDay: action.payload.user.birthDate ? dayjs(action.payload.user.birthDate).format('DD') : undefined,
      }
      state.loading = false
    })
    builder.addCase(getAccountInfo.rejected, state => {
      state.loading = false
    })
  },
})

export const accountInfoReducer = accountInfoSlice.reducer
