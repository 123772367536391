import React, { ComponentPropsWithRef, forwardRef } from 'react'
import { SerializedStyles, css } from '@emotion/react'
import { labelStyle } from '@/styles/form'
import { CUSTOM_FORM } from '../template/custom-design-style'

interface RadioButtonProps {
  label: React.ReactNode
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  checked: boolean
  name: string
  radioSize?: 'small' | 'middle'
  customLabelCss?: SerializedStyles
  disabled?: boolean
}

type RadioButtonType = ComponentPropsWithRef<'input'> & RadioButtonProps

export const RadioButton: React.FC<RadioButtonType> = forwardRef(
  ({ checked, label, value, name, customLabelCss, onChange, radioSize = 'small', disabled = false }, ref) => {
    return (
      <label css={[labelStyle, customLabelCss]} htmlFor={`${name}-${value}`}>
        <input
          css={[radioStyle, radioSize === 'middle' ? middleSize : {}]}
          onChange={onChange}
          id={`${name}-${value}`}
          name={name}
          checked={checked}
          type="radio"
          value={value}
          ref={ref}
          className={CUSTOM_FORM}
          disabled={disabled}
        />
        {label}
      </label>
    )
  },
)
RadioButton.displayName = 'RadioButton'

const radioStyle = css({
  appearance: 'none',
  outline: 'none',
  border: 'none',
  paddingLeft: '30px!important',
  position: 'relative',
  '&:before,&:after': {
    content: '""',
    display: 'block',
    borderRadius: '50%',
    position: 'absolute',
    margin: 'auto',
    top: 0,
    bottom: 0,
    left: 0,
  },
  '&:before': {
    width: '24px',
    height: '24px',
  },
  '&:after': {
    opacity: 0,
    height: '14px',
    width: '14px',
    right: '6px',
  },
  '&:checked:after': {
    opacity: 1,
  },
})

const middleSize = css({
  paddingLeft: '40px!important',
  '&:after': {
    right: '16px',
  },
})
