import React from 'react'
import { RadioButton } from '@/components/atoms/radio-button'
import { SelectableTimeSelection } from '@/features/checkIn/time-selection-slice'
import { Controller, UseFormReturn } from 'react-hook-form'
import { css } from '@emotion/react'
import { Divider } from '@/components/atoms/divider'
import dayjs from 'dayjs'
import { timeToMinutes } from '@/features/space/spaces-slice'
import { useAppSelector } from '@/app/hooks'
import { t } from 'i18next'
import { ErrorMessage } from '@/components/atoms/error-message'
import { createDateString } from '@/utils/date'
import { useTranslation } from 'react-i18next'

interface SelectGroupProps {
  useFormReturn: UseFormReturn<any>
  date: string
  timeSelection: SelectableTimeSelection
}

export const SelectGroup: React.FC<SelectGroupProps> = ({ date, useFormReturn, timeSelection }) => {
  const { control } = useFormReturn
  const { selectedReservations } = useAppSelector(state => state.checkIn.checkInData.fields)
  const { i18n } = useTranslation()

  const computeSelectableTimes = (date: string) => {
    let nextUsageTime: string
    return Object.keys(timeSelection.mergeAvailabilityTime[date] || {})
      .filter((time, i) => {
        // 開始時間間隔外をフィルター
        const addMinute = timeToMinutes(undefined, timeSelection.selection.usageInterval)
        if (i === 0 || timeToMinutes(nextUsageTime) <= timeToMinutes(time)) {
          nextUsageTime = dayjs(`${date} ${time}`).add(addMinute, 'minute').format('HH:mm')
          return true
        }
        return false
      })
      .filter(time => {
        // 終了時間が枠外であるときにフィルター
        const minUsageMin = timeToMinutes(timeSelection.selection.minUsageTime, timeSelection.selection.usageInterval)
        const endTime = dayjs(`${date} ${time}`)
          .add(minUsageMin - 15, 'minute')
          .format('HH:mm')
        return Object.keys(timeSelection.mergeAvailabilityTime[date]).includes(endTime)
      })
  }

  const isDisabledStartTime = (date: string, time: string): boolean => {
    const usageNumTotal = timeSelection.reservationsByTime[date].reduce((resp, reserveId) => {
      if (timeSelection.selection.stockPerReservation === 'numOfPeople') {
        return (resp += selectedReservations?.find(r => r.reservationId === reserveId)?.paxTotal || 1)
      } else {
        return (resp += 1)
      }
    }, 0)

    const minUsageIntervalMinutes = timeToMinutes(timeSelection.selection.minUsageTime, timeSelection.selection.usageInterval)
    const endTime = dayjs(`${date} ${time}`).add(minUsageIntervalMinutes, 'minute').format('HH:mm')
    let runningTime = time
    do {
      if (timeSelection.mergeAvailabilityTime[date][runningTime] < usageNumTotal) {
        return true
      }
      runningTime = dayjs(`${date} ${runningTime}`).add(15, 'minute').format('HH:mm')
    } while (endTime !== runningTime)
    return false
  }

  const makeRadioItems = (date: string) =>
    computeSelectableTimes(date).map(time => ({
      value: `${date} ${time}`,
      label: time,
      disabled: isDisabledStartTime(date, time),
    }))

  const hasEnabledRadioItems = (): boolean => {
    return radioItems.length ? radioItems.some(item => !item.disabled) : false
  }

  const radioItems: Array<{ value: string; label: string; disabled: boolean }> = makeRadioItems(date)

  const getTargetGuestName = (): string => {
    if ((selectedReservations?.length || 0) < 2) {
      return ''
    }
    return timeSelection.reservationsByTime[date]
      .map(reserveId => {
        const reserve = selectedReservations?.find(r => r.reservationId === reserveId)
        return t('honorific', { name: reserve?.guestName || reserve?.userName || '-' })
      })
      .join(', ')
  }

  return (
    <div>
      <h3 className="sub-title">
        {createDateString({ date, language: i18n.language })} {getTargetGuestName()}
      </h3>
      <div style={{ border: '1px solid #CCCCCC', borderRadius: 6 }}>
        <React.Fragment>
          <Controller
            name={
              hasEnabledRadioItems()
                ? `timeSelections.${timeSelection.selection.id}.${date}`
                : `disabledTimeSelections.${timeSelection.selection.id}.${date}`
            }
            control={control}
            rules={{ required: timeSelection.selection.isRequired && hasEnabledRadioItems() ? 'This field is required' : undefined }}
            render={({ field }) => {
              return (
                <>
                  {radioItems.map((item, i) => (
                    <React.Fragment key={`${field.name}-${item.value}`}>
                      <RadioButton
                        radioSize="middle"
                        checked={field.value === item.value}
                        label={item.label}
                        value={item.value}
                        name={field.name}
                        onChange={e => {
                          const value = e.target.value
                          field.onChange(value)
                        }}
                        customLabelCss={css({ padding: '24px 16px', marginBottom: 0 })}
                        disabled={item.disabled}
                      />
                      {i !== radioItems.length - 1 && <Divider />}
                    </React.Fragment>
                  ))}
                </>
              )
            }}
          />
        </React.Fragment>
      </div>
      {!hasEnabledRadioItems() && <ErrorMessage text={t('There is no availability.Please ask our staff upon check-in.')} />}
    </div>
  )
}
