import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'

import enJson from './locales/en.json'
import jaJson from './locales/ja.json'
import zhJson from './locales/zh.json'
import zhTwJson from './locales/zhTw.json'
import koJson from './locales/ko.json'
import moment from 'moment/moment'

import { z } from 'zod'
import { zodCustomErrorMap } from '@/utils/zod-validate-map'

export const LanguageType = {
  ja: 'ja',
  en: 'en',
  zh: 'zh',
  ko: 'ko',
  zhTw: 'zhTw'
} as const
export type LanguageType = (typeof LanguageType)[keyof typeof LanguageType]

const getBrowserLanguage = () => {
  const { languages, language } = window.navigator
  const browserLanguage = ((languages && languages[0]) || language).split('-')[0]
  return browserLanguage in LanguageType ? browserLanguage : undefined
}
const inQueryLang = new URL(window.location.href).searchParams.get('lang')

const defaultLanguage =
  localStorage.getItem('lang') || inQueryLang || getBrowserLanguage() || process.env.REACT_APP_DEFAULT_LANG || LanguageType.ja

i18n
  .use(initReactI18next)
  .init({
    lng: defaultLanguage,
    fallbackLng: false,
    resources: {
      en: { translation: enJson },
      ja: { translation: jaJson },
      zh: { translation: zhJson },
      zhTw: { translation: zhTwJson },
      ko: { translation: koJson },
    },
    nsSeparator: false,
  })
  .then(() => {
    dayjs.locale(process.env.REACT_APP_DEFAULT_LANG ?? LanguageType.ja)
    moment.locale(process.env.REACT_APP_DEFAULT_LANG ?? LanguageType.ja)
  })

z.setErrorMap(zodCustomErrorMap)

i18n.on('languageChanged', language => {
  localStorage.setItem('lang', language)
  if (i18n.languages.some(target => target === language)) {
    dayjs.locale(language)
    moment.locale(language)
  } else {
    dayjs.locale(process.env.REACT_APP_DEFAULT_LANG ?? LanguageType.ja)
    moment.locale(process.env.REACT_APP_DEFAULT_LANG ?? LanguageType.ja)
  }
})

export default i18n
