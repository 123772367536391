import { http } from '@/apis/aipass'
import { env } from '@/libs/env'
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const fetchTimeSelections = createAsyncThunk(
  'checkIn/timeSelections',
  async (arg: { hotelId: string; reservationsIds: string[] }, { rejectWithValue }) => {
    try {
      const response = await http({
        method: 'get',
        url: `${env('BASE_API_V3')}/custom-checkin/custom-checkin-time-selection`,
        params: { hotelId: arg.hotelId, reservationIds: arg.reservationsIds },
      })
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export type SelectableTimeSelection = {
  selection: TimeSelection
  mergeAvailabilityTime: AvailabilityTimes
  reservationsByTime: { [date: string]: string[] }
}

type AvailabilityTimes = {
  [date: string]: {
    [time: string]: number
  }
}
export type TimeSelection = {
  id: string
  title: string
  description: string
  isRequired: boolean
  checkinDate: string
  checkoutDate: string
  spaceId: string
  minUsageTime?: string
  usageInterval: number
  stockPerReservation: 'single' | 'numOfPeople'
  dayOfApplicable: 'firstNight' | 'lastDay' | 'everyDay'
  dayOfUse: 'appointedDay' | 'nextDay'
  salesHourEnd: string
}
export type SelectedTime = {
  [reservationId: string]: {
    [timeSelectionId: string]: {
      [date: string]: string
    }
  }[]
}

const storageKey = 'timeSelectionCheckInData'
const restoreSessionCustomCheckInData = () => {
  const storage = sessionStorage.getItem(storageKey)
  return storage ? JSON.parse(storage) : {}
}
const restoreTimeSelections = () => {
  const storage = sessionStorage.getItem(`${storageKey}.selection`)
  return storage ? JSON.parse(storage) : []
}

const timeSelectionsSlice = createSlice({
  name: 'fetchTimeSelections',
  initialState: {
    timeSelections: restoreTimeSelections() as SelectableTimeSelection[],
    selectedTimes: restoreSessionCustomCheckInData() as SelectedTime,
    loading: false,
  },
  reducers: {
    setSelectedTimes: (state, action: PayloadAction<SelectedTime>) => {
      state.selectedTimes = action.payload
      sessionStorage.setItem(storageKey, JSON.stringify(action.payload))
      sessionStorage.setItem(`${storageKey}.selection`, JSON.stringify(state.timeSelections))
    },
    clearTimeSelection: state => {
      state.selectedTimes = {}
      sessionStorage.removeItem(storageKey)
      sessionStorage.removeItem(`${storageKey}.selection`)
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchTimeSelections.fulfilled, (state, action: PayloadAction<SelectableTimeSelection[]>) => {
      state.timeSelections = action.payload
      state.loading = false
    })
    builder.addCase(fetchTimeSelections.rejected, state => {
      state.loading = false
    })
    builder.addCase(fetchTimeSelections.pending, state => {
      state.loading = true
    })
  },
})

export const { setSelectedTimes, clearTimeSelection } = timeSelectionsSlice.actions
export const timeSelectionsReducer = timeSelectionsSlice.reducer
