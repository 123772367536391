import React, { useEffect, useState } from 'react'
import { useAppSelector } from '@/app/hooks'
import { t } from 'i18next'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import { CheckBox } from '@/components/atoms/check-box'
import { Layout } from '@/components/template/layout'
import { css } from '@emotion/react'
import { Reservation, searchCheckIn, setReservationIds } from '@/features/checkIn/search-reservations-slice'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/app/store'
import { clearPayments, getCheckInPayments } from '@/features/checkIn/check-in-payment-slice'
import { CheckInData, updateCheckInData } from '@/features/checkIn/check-in-slice'
import { BorderButton, Button } from '@/components/atoms/button'
import { pcScreenStyle, spacerTopStyle } from '@/styles/common'
import { useNavigate } from 'react-router'
import { fetchPaymentSetting } from '@/features/hotel/payment-setting-by-hotel'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { hasPaymentPlugin } from '@/features/hotel/hotel-guide-slice'
import { createDateString } from '@/utils/date'
import { useQuerySearchParams } from '@/hooks/use-query-search-params'
import { Loading } from '@/components/organisms/loading'
import { fetchCustomFields } from '@/features/checkIn/custom-check-in-field-slice'
import { ReservationSelectCard } from '@/components/molecules/reservation-select-card'
import { errorHandler } from '@/libs/errors'
import { minScreen } from '@/styles/media-query'
import { formButtonStyle } from '@/styles/form'
import { useBreakPoint } from '@/hooks/use-break-point'
import { CheckInStep } from '@/components/organisms/checkIn/check-in-step'

export const ReservationConfirm = () => {
  const {
    i18n: { language },
  } = useTranslation()
  const searchParams = useQuerySearchParams<{ hotelId: string; telephone?: string }>()
  const { searchReservations, reservationIds, loading } = useAppSelector(state => state.checkIn.searchCheckIn)
  const getPaymentData = useAppSelector(state => state.checkIn.checkInPayments)
  const paymentSettings = useAppSelector(state => state.paymentSetting.settings)
  const checkInData = useAppSelector<CheckInData>(state => state.checkIn.checkInData.fields)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const { isPc } = useBreakPoint()

  const createLabel = (reservation: Reservation) => {
    const dateString = createDateString({ date: reservation.checkinDate, language })
    return (
      <>
        {dateString}
        <span style={{ paddingLeft: '0.5rem', fontSize: '14px' }}>
          {reservation.nights} {reservation.nights === 1 ? t('night') : t('nights')}
        </span>
      </>
    )
  }

  const onSubmit = async () => {
    const selectedReservations = searchReservations.filter(r => reservationIds.includes(r.reservationId))
    const firstCheckInDate = dayjs.unix(Math.min(...selectedReservations.map(r => dayjs(r.checkinDate).unix()))).format('YYYY-MM-DD')
    dispatch(
      updateCheckInData({
        checkInDate: firstCheckInDate,
        reservationIds,
        selectedReservations,
      }),
    )
    try {
      await dispatch(getCheckInPayments({ hotelId: searchParams.hotelId, reservationIds })).unwrap()
    } catch (error) {
      errorHandler({ error })
    }
    setIsSubmitted(true)
  }

  const loadCheckInList = async () => {
    if (!searchParams.telephone) {
      return navigate('/checkin')
    }

    await dispatch(searchCheckIn({ hotelId: searchParams.hotelId, reservationTel: searchParams.telephone }))
  }

  useEffect(() => {
    if (!isSubmitted || getPaymentData?.payments.reservations.length === 0) {
      return
    }
    navigate('/custom-checkin')
  }, [isSubmitted, paymentSettings])

  useEffect(() => {
    dispatch(fetchPaymentSetting(searchParams.hotelId))
    dispatch(hasPaymentPlugin())
    dispatch(fetchCustomFields({ hotelId: searchParams.hotelId }))
  }, [searchReservations])

  useEffect(() => {
    // Check same day reservation
    if (checkInData.reservationIds?.length) {
      dispatch(setReservationIds(checkInData.reservationIds))
      return
    }
    const reservationsAfterToday = searchReservations.filter(r => dayjs(r.checkinDate).endOf('D').isAfter(dayjs()))
    if (reservationsAfterToday.length === 0) {
      dispatch(setReservationIds([]))
      return
    }
    const nearCheckInDate = reservationsAfterToday[0].checkinDate
    const nearCheckInReservationIds = reservationsAfterToday.filter(r => r.checkinDate === nearCheckInDate).map(r => r.reservationId)
    dispatch(setReservationIds(nearCheckInReservationIds))
  }, [])

  useEffect(() => {
    if (isEmpty(searchReservations)) {
      loadCheckInList()
    }
    dispatch(clearPayments())
  }, [])

  return (
    <Layout>
      <Loading loading={loading}>
        <CheckInStep current={0} />
        <div css={[layoutStyle, pcScreenStyle]}>
          {searchReservations.map((reservation, index) => {
            const label = (
              <CheckBox
                label={createLabel(reservation)}
                checked={reservationIds.includes(reservation.reservationId)}
                name={reservation.reservationId}
                onChange={() => {
                  if (reservationIds.includes(reservation.reservationId)) {
                    const newReservationIds = reservationIds.filter(reservationId => reservationId !== reservation.reservationId)
                    dispatch(setReservationIds(newReservationIds))
                  } else {
                    dispatch(setReservationIds([...reservationIds, reservation.reservationId]))
                  }
                }}
              />
            )

            return (
              <div key={index} css={index !== 0 ? spacerTopStyle : undefined}>
                <ReservationSelectCard reservation={reservation} selectLabel={label} />
              </div>
            )
          })}
          <div css={isPc ? formButtonStyle : undefined}>
            <Button
              disabled={isEmpty(reservationIds)}
              buttonCss={spacerTopStyle}
              text={t('Next')}
              type="submit"
              onClick={() => onSubmit()}
            />
            <BorderButton
              buttonCss={[spacerTopStyle]}
              text={t('Search your reservation')}
              onClick={() => navigate('/checkin-reservation-search')}
            />
          </div>
        </div>
      </Loading>
    </Layout>
  )
}

const layoutStyle = css({
  paddingTop: '1rem',
  paddingInline: '1.5rem',
  [minScreen()]: {
    paddingInline: 0,
  },
})
