import React from 'react'
import { useAppSelector } from '@/app/hooks'

interface RequiredMarkProps {
  text?: string
}

export const RequiredMark: React.FC<RequiredMarkProps> = ({ text = '※' }) => {
  const design = useAppSelector(state => state.design)

  return <span style={{ color: design.text.textColor }}>{text}</span>
}
