import React from 'react'

import { useAppSelector } from '@/app/hooks'
import { UseFormReturn } from 'react-hook-form'
import { HtmlContent } from '../../atoms/html-content'
import { css } from '@emotion/react'
import { Divider } from '../../atoms/divider'
import { SelectGroup } from './select-group'
import { usePickByLanguage } from '@/hooks/use-pick-by-language'
import { RequiredMark } from '@/components/atoms/required-mark'

interface TimeSelectionInFormProps {
  useFormReturn: UseFormReturn<any>
}

export const TimeSelectionInForm: React.FC<TimeSelectionInFormProps> = ({ useFormReturn }) => {
  const { timeSelections } = useAppSelector(state => state.timeSelection)
  const pickByLanguage = usePickByLanguage()

  return (
    <React.Fragment>
      {timeSelections.map((timeSelection, i) => {
        return (
          <React.Fragment key={timeSelection.selection.id}>
            {i !== 0 && <Divider customCss={{ marginLeft: '-2rem', marginRight: '-2rem', marginBlock: '2rem' }} />}
            <div key={timeSelection.selection.title} css={timeSelectionStyle}>
              <p className="title">
                {pickByLanguage(timeSelection.selection.title)}
                {timeSelection.selection.isRequired && <RequiredMark />}
              </p>
              <div className="description">
                <HtmlContent text={pickByLanguage(timeSelection.selection.description)} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 32 }}>
                {Object.keys(timeSelection.mergeAvailabilityTime).map(date => {
                  return (
                    <SelectGroup
                      key={`${timeSelection.selection.id}-${date}`}
                      date={date}
                      timeSelection={timeSelection}
                      useFormReturn={useFormReturn}
                    />
                  )
                })}
              </div>
            </div>
          </React.Fragment>
        )
      })}
    </React.Fragment>
  )
}

const timeSelectionStyle = css({
  '.title': {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    marginBottom: 16,
    gap: 8,
    span: {
      fontSize: 9,
    },
  },
  '.description': {
    lineHeight: 1.5,
    marginBottom: 24,
  },
  '.sub-title': {
    fontWeight: 'bold',
    marginBottom: 12,
  },
})
